<template>
  <validation-observer>
    <div class="is-ancestor tile">
      <div class="is-parent tile">
        <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
        <div class="is-child tile box is-3">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Extract environment"
          >
            <b-field label="OFSC source" horizontal>
              <b-select
                v-model="extractEnvironment"
                placeholder="OFSC environment..."
                icon="server"
              >
                <option
                  v-for="option in environmentList"
                  :id="option.name"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.friendlyName }}
                </option>
              </b-select>
            </b-field>
            <span class="help is-danger">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Route date"
          >
            <b-field label="Route Date" horizontal>
              <b-datepicker
                v-model="routeDate"
                ::first-day-of-week="1"
                placeholder="Click to select..."
                icon="calendar-day"
              ></b-datepicker>
              <span class="help is-danger">{{ errors[0] }}</span>
            </b-field>
          </validation-provider>
          <div class="buttons" style="margin-top: 1em">
            <b-button
              icon-left="calendar-day"
              class="is-primary"
              @click="routeDate = new Date()"
            >
              Today
            </b-button>
            <b-button
              icon-left="times"
              type="is-danger"
              @click="routeDate = null"
            >
              Clear
            </b-button>
          </div>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Parking time"
          >
            <b-field label="Parking time (mins)">
              <b-input v-model="parkingTime" icon="parking"></b-input>
            </b-field>
            <span class="help is-danger">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Parent Resource"
          >
            <b-field label="Top level resource for routes">
              <b-input
                v-model="resourceParent"
                name="Parent Resource"
                icon="sitemap"
              ></b-input>
            </b-field>
            <span class="help is-danger">{{ errors[0] }}</span>
          </validation-provider>
          <div class="buttons">
            <b-button
              type="is-primary"
              :disabled="!isComplete"
              icon-left="route"
              @click.prevent="retrieveRoutes"
            >
              Retrieve Routes
            </b-button>
            <b-button
              type="is-primary"
              :disabled="hasRouteData"
              icon-left="car-side"
              @click.prevent="retrievePredictedTravel"
            >
              Retrieve Predicted Travel
            </b-button>
            <b-button
              type="is-danger"
              icon-left="times"
              :disabled="hasRouteData"
              @click.prevent="discardData"
            >
              Discard Routes
            </b-button>
          </div>
          <div>
            <b-field>
              <b-select
                v-model="optimism"
                name="Travel Prediction Type"
                placeholder="Travel Prediction Type"
                icon="car-side"
              >
                <option
                  v-for="option in predictionOptions"
                  :id="option.value"
                  :key="option.name"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <div class="buttons">
              <b-button
                type="is-primary"
                icon-left="upload"
                :disabled="disableApplyButton"
                @click.prevent="applyTravel"
              >
                Update predicted travel
              </b-button>
              <b-button
                :disabled="routeData.length === 0"
                icon-left="download"
                type="is-info"
                @click="downloadCsv"
              >
                Export CSV
              </b-button>
            </div>
          </div>
        </div>
        <div class="is-child tile box" style="overflow-x: scroll">
          <div v-if="error" class="help is-danger is-size-4">
            {{ error.response.data || error }}
          </div>
          <h2
            v-if="routeDateForDisplay"
            class="has-text-success has-text-weight-bold is-size-5 has-text-right"
          >
            Engineer routes for {{ routeDateForDisplay }}
          </h2>

          <b-table
            :data="routeData"
            paginated
            per-page="25"
            detailed
            detail-key="resourceId"
            style="overflow-x: scroll"
          >
            <b-table-column
              v-slot="props"
              sortable
              field="resourceId"
              label="Engineer"
              >{{ props.row.resourceId }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              sortable
              field="numberOfActivities"
              label="No. of Activities"
              numeric
              >{{ props.row.numberOfActivities }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              sortable
              field="totalOfscTravel"
              label="OFSC Total Travel"
              meta="Minutes"
              numeric
              >{{ props.row.totalOfscTravel }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              sortable
              field="averageOfscTravel"
              label="OFSC Average Travel"
              meta="Minutes"
              numeric
              >{{ props.row.averageOfscTravel }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              sortable
              field="predictedMinutes"
              :label="dataSource === 'google' ? 'Best Guess' : 'Balanced'"
              meta="Minutes"
              numeric
            >
              <b-icon
                :type="
                  getDifferenceColor(
                    props.row.totalOfscTravel,
                    props.row.predictedMinutes,
                  )
                "
                :icon="
                  getDifferenceIcon(
                    props.row.totalOfscTravel,
                    props.row.predictedMinutes,
                  )
                "
              ></b-icon>
              <span
                :class="
                  getDifferenceColor(
                    props.row.totalOfscTravel,
                    props.row.predictedMinutes,
                    true,
                  )
                "
                >{{ props.row.predictedMinutes }}</span
              >
            </b-table-column>
            <b-table-column
              v-slot="props"
              sortable
              field="pessimisticPredictedMinutes"
              :label="dataSource === 'google' ? 'Pessimistic' : 'Shortest'"
              meta="Minutes"
              numeric
            >
              <b-icon
                :type="
                  getDifferenceColor(
                    props.row.totalOfscTravel,
                    props.row.pessimisticPredictedMinutes,
                  )
                "
                :icon="
                  getDifferenceIcon(
                    props.row.totalOfscTravel,
                    props.row.pessimisticPredictedMinutes,
                  )
                "
              ></b-icon>
              <span
                :class="
                  getDifferenceColor(
                    props.row.totalOfscTravel,
                    props.row.pessimisticPredictedMinutes,
                    true,
                  )
                "
                >{{ props.row.pessimisticPredictedMinutes }}</span
              >
            </b-table-column>
            <b-table-column
              v-slot="props"
              sortable
              field="optimisticPredictedMinutes"
              :label="dataSource === 'google' ? 'Optimistic' : 'Fastest'"
              meta="Minutes"
              numeric
            >
              <b-icon
                :type="
                  getDifferenceColor(
                    props.row.totalOfscTravel,
                    props.row.optimisticPredictedMinutes,
                  )
                "
                :icon="
                  getDifferenceIcon(
                    props.row.totalOfscTravel,
                    props.row.optimisticPredictedMinutes,
                  )
                "
              ></b-icon>
              <span
                :class="
                  getDifferenceColor(
                    props.row.totalOfscTravel,
                    props.row.optimisticPredictedMinutes,
                    true,
                  )
                "
                >{{ props.row.optimisticPredictedMinutes }}</span
              >
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <div class="media-content">
                <div class="content">
                  <p>
                    <span
                      v-html="formatRouteArrayForDisplay(props.row.route)"
                    ></span>
                  </p>
                </div>
              </div>
            </template>
            <template slot="header" slot-scope="props">
              <b-tooltip
                position="is-left"
                :active="!!props.column.meta"
                :label="props.column.meta ? props.column.meta : ''"
                dashed
                type="is-dark"
                >{{ props.column.label }}</b-tooltip
              >
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import configData from '@/config/config.json';
import axios from 'axios';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'RealTimeTravel',
  url: configData.realTimeTravel.ofscRetrievalUrl,
  retrievalUrl: configData.realTimeTravel.retrievalUrl,
  updateUrl: configData.realTimeTravel.updateUrl,
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      parkingTime: configData.realTimeTravel.defaultParkingTime,
      routeDate: null,
      dataSource: configData.realTimeTravel.dataSource,
      resourceParent: configData.realTimeTravel.topLevelResource,
      isLoading: false,
      extractEnvironment: null,
      optimism: null,
      predictionOptions: [
        {
          name: this.dataSource === 'google' ? 'Best Guess' : 'Balanced',
          value: 'best_guess',
        },
        {
          name: this.dataSource === 'google' ? 'Pessimistic' : 'Shortest',
          value: 'pessimistic',
        },
        {
          name: this.dataSource === 'google' ? 'Optimistic' : 'Fastest',
          value: 'optimistic',
        },
      ],
      data: [],
      error: null,
    };
  },
  computed: {
    disableApplyButton() {
      if (!(this.optimism && this.extractEnvironment)) {
        return true;
      } else {
        return false;
      }
    },
    routeDateForDisplay() {
      if (this.routeData && this.routeData[0]) {
        let formattedDate = this.$moment(
          this.routeData[0].date,
          'YYYY-MM-DD',
        ).format('DD/MM/YY');
        return formattedDate || '';
      } else {
        return '';
      }
    },
    hasRouteData() {
      return !(this.routeData && this.routeData.length > 0);
    },
    isComplete() {
      if (this.routeDate && this.extractEnvironment) {
        return true;
      } else {
        return false;
      }
    },
    requestHeader() {
      let auth = `Bearer ${this.idToken}`;
      let headerObj = {
        Authorization: auth,
      };
      return headerObj;
    },
    routeData() {
      const unfilteredData = this.$store.getters['storeRtt/resourceRoutes'];
      return unfilteredData.filter((item) => {
        return item.route.length !== 0;
      });
    },
  },
  created() {
    this.fetchEnvironments(false);
  },
  methods: {
    downloadCsv() {
      if (this.routeData && this.routeData.length > 0) {
        const dataForCsv = [];
        this.routeData.forEach((item) => {
          let newItem = {};
          Object.assign(newItem, item);
          delete newItem.route;
          dataForCsv.push(newItem);
        });
        this.exportCsv('realTimeTravel', dataForCsv);
      }
    },
    getDifferenceIcon(ofscVal, predictedVal) {
      if (ofscVal < predictedVal) {
        return 'long-arrow-alt-up';
      }
      if (ofscVal > predictedVal) {
        return 'long-arrow-alt-down';
      } else {
        return 'minus';
      }
    },
    getDifferenceColor(ofscVal, predictedVal, text) {
      if (ofscVal < predictedVal) {
        return text ? 'has-text-danger' : 'is-danger';
      }
      if (ofscVal > predictedVal) {
        return text ? 'has-text-success' : 'is-success';
      } else {
        return 'is-primary';
      }
    },
    formatRouteArrayForDisplay(route) {
      let output = `<table class="subtable">
                      <tr style="font-weight:700;" class="has-text-primary">
                        <td>Activity ID</td>
                        <td>OFSC Travel</td>
                        <td>${
                          this.dataSource === 'google'
                            ? 'Best Guess'
                            : 'Balanced'
                        }</td>
                        <td>${
                          this.dataSource === 'google'
                            ? 'Optimistic'
                            : 'Fastest'
                        }</td>
                        <td>${
                          this.dataSource === 'google'
                            ? 'Pessimistic'
                            : 'Shortest'
                        }</td>
                        <td>Postcode</td>
                        <td>Lat,Lng</td>
                      </tr>`;
      route.forEach((activity) => {
        let activityId;
        if (activity.ActivityId === 'SL') {
          activityId = 'Start Loc.';
        } else if (activity.ActivityId === 'EL') {
          activityId = 'End Loc.';
        } else {
          activityId = activity.ActivityId;
        }
        output += `
        <tr>
          <td>${activityId}</td>
          <td>${activity.OFSCTravelTime || ''}</td>
          <td>${activity.predictedMinutes || ''}</td>
          <td>${activity.optimisticPredictedMinutes || ''}</td>
          <td>${activity.pessimisticPredictedMinutes || ''}</td>
          <td>${activity.Postcode}</td>
          <td>${activity.Latitude},${activity.Longitude}</td>
        </tr>`;
      });
      output += '</table>';
      return output;
    },
    async applyTravel() {
      try {
        this.$buefy.toast.open({
          message: `Updating travel time for routes on ${this.routeDateForDisplay}`,
          type: 'is-primary',
        });
        this.isLoading = true;
        this.error = null;
        let params = new URLSearchParams();
        params.append('environment', this.extractEnvironment);
        params.append('customer', this.customer);
        params.append('resourceParent', this.resourceParent);
        params.append('date', this.routeDate);
        params.append('optimism', this.optimism);
        params.append('userId', this.userId);
        let auth = `Bearer ${this.idToken}`;
        try {
          await axios({
            method: 'POST',
            url: this.$options.updateUrl,
            headers: {
              Authorization: auth,
            },
            data: this.routeData,
            params: params,
          });
          this.$buefy.toast.open(
            {
              message: `Travel time updated for routes on ${this.routeDateForDisplay}`,
              type: 'is-success',
            },
            2000,
          );
        } catch (err) {
          this.$buefy.toast.open({
            message: `Something went wrong`,
            type: 'is-danger',
          });
          this.error = err;
        } finally {
          this.isLoading = false;
        }
      } catch (err) {
        console.error('Failed to get auth token', err);
      } finally {
        this.isLoading = false;
      }
    },
    async discardData() {
      this.$store.dispatch('storeRtt/discardResourceRoutes');
    },
    async retrieveRoutes() {
      try {
        this.isLoading = true;
        this.error = null;
        let params = new URLSearchParams();
        params.append('environment', this.extractEnvironment);
        params.append('customer', this.customer);
        params.append('resourceParent', this.resourceParent);
        const formattedDate = this.$moment(this.routeDate).format('YYYY-MM-DD');
        params.append('date', formattedDate);
        params.append('userId', this.userId);
        let auth = `Bearer ${this.idToken}`;
        try {
          let response = await axios.get(this.$options.url, {
            params: params,
            timeout: 600000,
            headers: {
              Authorization: auth,
            },
          });
          this.data = response.data;
          this.$store.dispatch('storeRtt/storeResourceRoutes', response.data);
        } catch (err) {
          this.error = err;
          console.log(err);
        } finally {
          this.isLoading = false;
        }
      } catch (err) {
        console.error('Failed to get auth token', err);
      } finally {
        this.isLoading = false;
      }
    },
    async retrievePredictedTravel() {
      try {
        this.isLoading = true;
        this.error = null;
        let auth = `Bearer ${this.idToken}`;
        let params = new URLSearchParams();
        params.append('dataSource', this.dataSource);
        params.append('parkingTime', this.parkingTime);
        try {
          let config = {
            method: 'POST',
            url: this.$options.retrievalUrl,
            headers: {
              Authorization: auth,
            },
            timeout: 600000,
            data: this.routeData,
            params: params,
          };

          let response = await axios(config);
          this.data = response.data;
          this.$store.dispatch('storeRtt/storeResourceRoutes', response.data);
        } catch (err) {
          console.error('Failed to get route data', err);
        } finally {
          this.isLoading = false;
        }
      } catch (err) {
        console.error('Failed to get auth token', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped>
.subtable tr td {
  white-space: nowrap;
}
</style>
