import { render, staticRenderFns } from "./RealTimeTravel.vue?vue&type=template&id=199fe639&scoped=true&"
import script from "./RealTimeTravel.vue?vue&type=script&lang=js&"
export * from "./RealTimeTravel.vue?vue&type=script&lang=js&"
import style0 from "./RealTimeTravel.vue?vue&type=style&index=0&id=199fe639&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199fe639",
  null
  
)

export default component.exports